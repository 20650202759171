import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    html {
        font-family: ${(props) => props.theme.fontFamily};
        scroll-behavior: smooth;
    }

    body {
        margin: 0;
        background-color: ${(props) => props.theme.pallette.background.default};
        
        &::-webkit-scrollbar {
            display: none;
        }
    }

    #gatsby-focus-wrapper {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
    }
`;

export * from "./shadeColor";
export * from "./theme";
export default GlobalStyle;
