import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  bottom: 24px;
  right: 24px;
  transform: rotate(90deg) scale(0);
  transition: transform 0.6s;

  &.visible {
    transform: rotate(-90deg) scale(1);
  }
`;
