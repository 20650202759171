import styled from "styled-components";

export type TextProps = {
  align: "start" | "center" | "end";
};

export const H1 = styled.h1<TextProps>`
  font-size: 32px;
  color: ${(props) => props.theme.pallette.text.default};
  font-weight: 700;
  text-align: ${(props) => props.align};
  margin: 24px 0;

  @media (min-width: 768px) {
    font-size: 40px;
    margin: 56px 0;
  }
`;

export const H3 = styled.h3<TextProps>`
  font-size: 24px;
  color: ${(props) => props.theme.pallette.text.default};
  font-weight: 700;
  text-align: ${(props) => props.align};
`;

export const H4 = styled.h4<TextProps>`
  font-size: 20px;
  color: ${(props) => props.theme.pallette.text.default};
  font-weight: 700;
  text-align: ${(props) => props.align};
  margin: 0;
`;

export const H5 = styled.h5<TextProps>`
  font-size: 16px;
  color: ${(props) => props.theme.pallette.text.default};
  font-weight: 700;
  text-align: ${(props) => props.align};
  margin: 0;

  @media (min-width: 768px) {
    font-size: 24px;
  }
`;

export const P = styled.p<TextProps>`
  font-size: 16px;
  color: ${(props) => props.theme.pallette.text.default};
  font-weight: 400;
  text-align: ${(props) => props.align};

  a {
    text-decoration: none;
    color: ${(props) => props.theme.pallette.primary.main};
  }
`;

export const Small = styled.small<TextProps>`
  display: block;
  font-size: 12px;
  color: ${(props) => props.theme.pallette.text.default};
  font-weight: 300;
  text-align: ${(props) => props.align};
`;

export const Strong = styled.strong<TextProps>`
  display: block;
  font-size: 14px;
  color: ${(props) => props.theme.pallette.text.default};
  font-weight: 500;
  text-align: ${(props) => props.align};
`;
