import * as React from "react";

import Icon from "../Icon";

import { Container } from "./CircleButton.styles";

export interface CircleButtonProps {
  children?: React.ReactChild | React.ReactChild[];
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  icon?: string;
  aria: string;
}

const Button: React.FC<CircleButtonProps> = ({
  children,
  onClick,
  icon,
  aria,
}) => {
  return (
    <Container onClick={onClick} aria-label={aria}>
      {icon && <Icon name={icon} />}
      {children}
    </Container>
  );
};

Button.defaultProps = {
  onClick: () => {},
};

export default Button;
