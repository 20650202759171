import * as React from "react";
import cn from "classnames";
import { useLocation } from "@reach/router";

import Socials from "@components/Socials";
import Logo from "@components/Logo";
import { CircleButton } from "@components/atoms";

import links from "@constants/links";

import { useSidebarContext } from "@contexts/Sidebar";

import {
  Container,
  Content,
  Controls,
  Navigation,
  NavigationLink,
} from "./Header.styles";

export interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  const { open, setOpen } = useSidebarContext();

  const { pathname } = useLocation();

  return (
    <Container>
      <Content>
        <Logo />
        <Navigation>
          {links.map((item) => (
            <li key={item.path}>
              <NavigationLink
                className={cn({
                  active: item.isActive(pathname),
                })}
                to={item.active || item.path}
              >
                {item.label}
              </NavigationLink>
            </li>
          ))}
        </Navigation>
        <Controls>
          <Socials />
          <CircleButton
            aria="open-sidebar"
            icon="hamburger"
            onClick={() => setOpen(!open)}
          />
        </Controls>
      </Content>
    </Container>
  );
};

export default Header;
