import styled from "styled-components";

export const Container = styled.main`
  margin: 104px 16px;
`;

export const Content = styled.section`
  max-width: 1116px;
  margin: 0 auto;
`;
