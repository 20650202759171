import * as React from "react";
import cn from "classnames";

import { Container } from "./Button.styles";

export interface ButtonProps {
  children: React.ReactChild | React.ReactChild[];
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  block?: boolean;
  type?: "submit" | "button";
  disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  block,
  type,
  disabled,
}) => {
  return (
    <Container
      type={type}
      disabled={disabled}
      className={cn({ "full-wdith": block })}
      onClick={onClick}
    >
      {children}
    </Container>
  );
};

Button.defaultProps = {
  type: "button",
  onClick: () => {},
  disabled: false,
};

export default Button;
