import styled from "styled-components";

import { shadeColor } from "@styles";

export const Container = styled.button`
  border: 0;
  border-radius: 10px;
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  color: ${(props) => props.theme.pallette.text.button};
  background-color: ${(props) => props.theme.pallette.primary.main};
  transition: background-color 600ms;
  will-change: background-color;
  text-align: center;
  padding: 8px 24px;
  width: fit-content;
  cursor: pointer;
  user-select: none;

  &:disabled {
    cursor: not-allowed;
    background-color: ${(props) =>
      shadeColor(props.theme.pallette.primary.main, 20)};
  }

  &.full-wdith {
    width: 100%;
  }
`;
