import styled from "styled-components";

export const Container = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;

  & > li {
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 20px;
    }

    &:hover {
      svg {
        fill: ${(props) => props.theme.pallette.primary.main};
      }
    }

    a {
      display: flex;
      align-items: center;
    }

    svg {
      fill: ${(props) => props.theme.pallette.text.default};
      transition: fill 300ms;
    }
  }
`;
