import styled from "styled-components";
import { Link, GatsbyLinkProps } from "gatsby";

// @ts-ignore
import Icon from "@assets/icons/logo.inline.svg";

export const LogoIcon = styled(Icon)`
  width: 30px;
  margin-right: 12px;
  fill: ${(props) => props.theme.pallette.primary.main};

  &.small {
    width: 25px;
  }
`;

export const Container = styled(Link)<GatsbyLinkProps<{}>>`
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  color: ${(props) => props.theme.pallette.text.default};

  &.small {
    font-size: 16px;
  }
`;
