import * as React from "react";

export interface IconProps {
  name: string;
  size?: number;
}

const Icon: React.FC<IconProps> = ({ name, size }) => {
  // Import icon on demand
  const FoundIcon = require(`@assets/icons/${name}.inline.svg`).default;

  // If it doesn't exist render nothing
  if (!FoundIcon) return null;

  return <FoundIcon style={{ width: size, height: size }} />;
};

Icon.defaultProps = {
  size: 15,
};

export default Icon;
