const links = [
  {
    active: "/",
    path: "/home",
    label: "Home",
    isActive: (pathname: string) => {
      return pathname === "/" || pathname === "/home";
    },
  },
  {
    active: "/category/react",
    path: "/category/react",
    label: "Articles",
    isActive: (pathname: string) => {
      return pathname.includes("/category/");
    },
  },
  {
    path: "/contact",
    label: "Contact",
    isActive: (pathname: string) => {
      return pathname === "/contact" || pathname === "/contact/";
    },
  },
  {
    path: "/about-us",
    label: "About Us",
    isActive: (pathname: string) => {
      return pathname === "/about-us" || pathname === "/about-us/";
    },
  },
];

export default links;
