import styled from "styled-components";
import { Link, GatsbyLinkProps } from "@reach/router";

export const EzoicCertified = styled.div``;

export const Container = styled.footer`
  margin-top: auto;
`;

export const Content = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 32px 12px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }

  & > *:not(:last-child) {
    margin-bottom: 16px;

    @media (min-width: 768px) {
      margin-bottom: 0;
    }
  }

  @media (min-width: 1024px) {
    margin: 32px auto;
    max-width: 1116px;
  }
`;

export const Navigation = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: center;
  display: flex;

  & > *:not(:last-child) {
    margin-right: 16px;
  }
`;

export const NavigationLink = styled(Link)<GatsbyLinkProps<{}>>`
  text-decoration: none;
  color: ${(props) => props.theme.pallette.text.default};
  background-color: transparent;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  padding: 8px 20px;
  font-smooth: antialiased;
`;

export const Copyright = styled.span`
  color: ${(props) => props.theme.pallette.text.default};
  font-weight: 300;
`;
