import * as React from "react";

import { Container } from "./Input.styles";

export interface InputProps {
  id: string;
  placeholder: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  value?: string;
}

const Input: React.FC<InputProps> = ({ id, placeholder, onChange, value }) => {
  if (value === undefined) {
    return (
      <Container
        id={id}
        name={id}
        placeholder={placeholder}
        onChange={onChange}
      />
    );
  }

  return (
    <Container
      id={id}
      name={id}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
    />
  );
};

Input.defaultProps = {
  onChange: () => {},
};

export default Input;
