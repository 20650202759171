import * as React from "react";

const { createContext, useContext } = React;

type SidebarContextProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const SidebarContext = createContext<SidebarContextProps>({
  open: true,
  setOpen: () => false,
});

export default SidebarContext;
export const useSidebarContext = () => useContext(SidebarContext);
