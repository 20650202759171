import styled from "styled-components";
import { Link, GatsbyLinkProps } from "gatsby";

const size = 300;
const sidebarIndex = 9999;

export const Container = styled.aside`
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: ${size}px;
  background-color: ${(props) => props.theme.pallette.background.paper};
  box-shadow: ${(props) => props.theme.utils.boxShadowLight};
  transition: transform 600ms ease-out;
  transform: translateX(${size + 50}px);
  z-index: ${sidebarIndex};

  &.open {
    transform: translateX(0);
  }
`;

export const Overlay = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: ${(props) => props.theme.pallette.background.default};
  opacity: 0;
  z-index: -1;
  will-change: z-index;
  transition: opacity 600ms ease-out, z-index 600ms ease-out;

  &.open {
    z-index: ${sidebarIndex - 1};
    opacity: 0.6;
  }
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  padding: 32px 40px;
  padding-top: 56px;
  height: calc(100% - 88px);

  & > *:last-child {
    margin-top: auto;
  }
`;

export const Navigation = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 56px;

  li {
    padding-bottom: 12px;
    margin-bottom: 8px;
  }
`;

export const NavigationLink = styled(Link)<GatsbyLinkProps<{}>>`
  text-decoration: none;
  color: ${(props) => props.theme.pallette.text.default};
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  font-smooth: antialiased;
  will-change: color;
  transition: color 300ms;

  &:hover {
    color: ${(props) => props.theme.pallette.text.default};
  }

  &.active {
    color: ${(props) => props.theme.pallette.primary.main};
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
`;
