import * as React from "react";

import SidebarContext from "./Sidebar.context";

export interface SidebarProviderProps {
  children: React.ReactChild | React.ReactChild[];
}

const { useState } = React;

const SidebarProvider: React.FC<SidebarProviderProps> = ({ children }) => {
  const [open, setOpen] = useState(false);

  return (
    <SidebarContext.Provider value={{ open, setOpen }}>
      {children}
    </SidebarContext.Provider>
  );
};

export default SidebarProvider;
