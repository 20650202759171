import * as React from "react";

import { Container } from "./TextArea.styles";

export interface TextAreaProps {
  id: string;
  placeholder: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
}

const TextArea: React.FC<TextAreaProps> = ({ id, placeholder, onChange }) => {
  return (
    <Container
      id={id}
      name={id}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
};

TextArea.defaultProps = {
  onChange: () => {},
};

export default TextArea;
