import * as React from "react";

import { H1, H3, H4, H5, P, Small, Strong } from "./Typography.styles";

export interface TypographyProps {
  children: React.ReactChild | React.ReactChild[];
  variant: "h1" | "h3" | "h4" | "h5" | "body" | "small" | "strong";
  align?: "start" | "center" | "end";
}

const Typography: React.FC<TypographyProps> = ({
  children,
  variant,
  align,
}) => {
  switch (variant) {
    case "h1":
      return <H1 align={align}>{children}</H1>;
    case "h3":
      return <H3 align={align}>{children}</H3>;
    case "h4":
      return <H4 align={align}>{children}</H4>;
    case "h5":
      return <H5 align={align}>{children}</H5>;
    case "body":
      return <P align={align}>{children}</P>;
    case "small":
      return <Small align={align}>{children}</Small>;
    case "strong":
      return <Strong align={align}>{children}</Strong>;
    default:
      return <P align={align}>{children}</P>;
  }
};

Typography.defaultProps = {
  align: "start",
};

export default Typography;
