import * as React from "react";
import cn from "classnames";

import { CircleButton } from "@components/atoms";

import { useScrollPosition } from "./ScrollTop.hooks";

import { Container } from "./ScrollTop.styles";

const ScrollTop: React.FunctionComponent = () => {
  const { scrollOffset } = useScrollPosition();

  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <Container className={cn({ visible: scrollOffset > 0 })}>
      <CircleButton
        icon="chevron-right"
        aria="scroll-top"
        onClick={scrollTop}
      />
    </Container>
  );
};

export default ScrollTop;
