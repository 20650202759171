import * as React from "react";
import { ThemeProvider } from "styled-components";

import ScrollTop from "@components/ScrollTop";
import Sidebar from "@components/Sidebar";
import Header from "@components/Header";
import Footer from "@components/Footer";

import SidebarProvider from "@contexts/Sidebar";

import GlobalStyle, { lightTheme } from "@styles";
import { Container, Content } from "./Layout.styles";

export interface LayoutProps {
  children: React.ReactChild | React.ReactChild[];
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <ThemeProvider theme={lightTheme}>
      <SidebarProvider>
        <Header />
        <Sidebar />
      </SidebarProvider>
      <GlobalStyle />
      <Container>
        <Content>{children}</Content>
      </Container>
      <Footer />
      <ScrollTop />
    </ThemeProvider>
  );
};

export default Layout;
