module.exports = {
  title: "Upbeat Code",
  author: {
    name: "Bernard Bado",
    summary: `teaching others how to become React ninja`,
  },
  siteUrl: "https://www.upbeatcode.com",
  domain: "upbeatcode.com",
  description:
    "Become better React developer. Following our tips, tricks and real life experiences.",
  social: {
    twitter: `CodeWithBernard`,
  },
  analyticsId: "UA-161503913-8",
  publisherId: "ca-pub-8412028141816459",
};
