import styled from "styled-components";

const size = 37;

export const Container = styled.button`
  border: none;
  border-radius: 50%;
  background-color: ${(props) => props.theme.pallette.primary.main};
  width: ${size}px;
  height: ${size}px;
  box-shadow: ${(props) => props.theme.utils.boxShadowLight};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    fill: ${(props) => props.theme.pallette.text.button};
  }
`;
