import * as React from "react";
import cn from "classnames";
import { useLocation } from "@reach/router";

import Socials from "@components/Socials";
import Logo from "@components/Logo";

import { useSidebarContext } from "@contexts/Sidebar";

import links from "@constants/links";

import {
  Container,
  Content,
  Navigation,
  NavigationLink,
  Footer,
  Overlay,
} from "./Sidebar.styles";

export interface SidebarProps {}

const Sidebar: React.FC<SidebarProps> = () => {
  const { open, setOpen } = useSidebarContext();

  const { pathname } = useLocation();

  const closeSidebar = () => setOpen(false);

  return (
    <>
      <Overlay className={cn({ open })} onClick={closeSidebar} />
      <Container className={cn({ open })}>
        <Content>
          <Logo size="small" />
          <Navigation>
            {links.map((item) => (
              <li className="navigation-link" key={item.path}>
                <NavigationLink
                  onClick={closeSidebar}
                  className={cn({
                    active: pathname === (item.active || item.path),
                  })}
                  to={item.active || item.path}
                >
                  {item.label}
                </NavigationLink>
              </li>
            ))}
          </Navigation>
          <Footer>
            <Socials />
          </Footer>
        </Content>
      </Container>
    </>
  );
};

export default Sidebar;
