import * as React from "react";
import cn from "classnames";

export interface LogoProps {
  size?: "default" | "small";
}

import { Container, LogoIcon } from "./Logo.styles";

const Logo: React.FC<LogoProps> = ({ size }) => {
  return (
    <Container to="/" className={cn({ small: size === "small" })}>
      <LogoIcon size={size} className={cn({ small: size === "small" })} />
      Upbeat Code
    </Container>
  );
};

Logo.defaultProps = {
  size: "default",
};

export default Logo;
