import React from "react";

const { useState, useEffect } = React;

export const useScrollPosition = () => {
  const [scrollOffset, setScrollOffset] = useState(0);

  useEffect(() => {
    const onScroll = () => {
      setScrollOffset(window.scrollY - window.innerHeight);
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollOffset]);

  return { scrollOffset };
};
