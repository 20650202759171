import * as React from "react";
import { useTheme } from "styled-components";

const Wave: React.FunctionComponent = () => {
  const theme = useTheme();

  return (
    <>
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stopColor={theme.pallette.primary.main}></stop>
          <stop offset="100%" stopColor={theme.pallette.primary.main}></stop>
        </linearGradient>
      </defs>
      <path
        d="M33 1c-3.3 0-3.3 4-6.598 4C23.1 5 23.1 1 19.8 1c-3.3 0-3.3 4-6.599 4-3.3 0-3.3-4-6.6-4S3.303 5 0 5"
        stroke="url(#gradient)"
        strokeWidth="2"
        fill="none"
      />
    </>
  );
};

export default Wave;
