import * as React from "react";

import { Icon } from "@components/atoms";

import { Container } from "./Socials.styles";

export interface SocialsProps {}

const socials = [
  { icon: "facebook-square", href: "https://www.facebook.com/upbeat.code" },
  { icon: "medium", href: "https://medium.com/@bernardbad" },
];

const Socials: React.FC<SocialsProps> = () => {
  return (
    <Container>
      {socials.map((item) => (
        <li key={item.icon}>
          <a href={item.href} target="_blank" rel="noopener noreferrer">
            <Icon size={18} name={item.icon} />
          </a>
        </li>
      ))}
    </Container>
  );
};

export default Socials;
