import styled from "styled-components";
import { Link, GatsbyLinkProps } from "gatsby";

export const Container = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${(props) => props.theme.pallette.background.paper};
  box-shadow: ${(props) => props.theme.utils.boxShadowLight};
  z-index: 1;
`;

export const Content = styled.nav`
  display: flex;
  align-items: center;
  margin: 32px 12px;

  @media (min-width: 1024px) {
    margin: 32px auto;
    max-width: 1116px;
  }
`;

export const Navigation = styled.ul`
  list-style: none;
  display: none;
  margin: 0;
  padding: 0;
  align-items: center;
  margin-left: 64px;

  @media (min-width: 1024px) {
    display: flex;
  }

  & > *:not(:last-child) {
    margin-right: 16px;
  }
`;

export const NavigationLink = styled(Link)<GatsbyLinkProps<{}>>`
  text-decoration: none;
  color: ${(props) => props.theme.pallette.text.default};
  background-color: transparent;
  position: relative;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  padding: 8px 20px;
  border-radius: 24px;
  font-smooth: antialiased;
  will-change: color;
  transition: color 300ms;

  &:hover {
    color: ${(props) => props.theme.pallette.text.default};
  }

  &::after {
    content: "";
    position: absolute;
    left: 12px;
    right: 12px;
    bottom: 0;
    height: 2px;
    will-change: background-color;
    transition: background-color 300ms;
    background-color: transparent;
  }

  &.active {
    color: ${(props) => props.theme.pallette.text.default};

    &::after {
      background-color: ${(props) => props.theme.pallette.primary.main};
    }
  }
`;

export const Controls = styled.div`
  margin-left: auto;
  display: flex;

  & > ul:first-child {
    margin-right: 48px;
    display: none;

    @media (min-width: 1024px) {
      display: flex;
    }
  }

  & > * {
    &:not(:last-child) {
      margin-right: 12px;
    }
  }
`;
