import { DefaultTheme } from "styled-components";

export const darkTheme: DefaultTheme = {
  pallette: {
    primary: {
      main: "#a55eea",
    },
    secondary: {
      main: "#fc5c65",
    },
    background: {
      default: "#0e141b",
      paper: "#151f28",
      input: "#0f161c",
      divider: "rgb(0 0 0 / 35%)",
      code: "#151f28",
    },
    text: {
      default: "#ffffff",
      secondary: "#ffffff",
      input: "#b0b3b8",
      button: "#ffffff",
      heading: "#fed330",
    },
  },
  utils: {
    boxShadowLight: "0px 2px 4px 0px rgb(0 0 0 / 5%)",
    boxShadowCard: "0px 2px 4px 0px rgb(0 0 0 / 5%)",
    boxShadowPrimary: "0 8px 20px rgb(165 94 234 / 25%)",
    border: "1px solid #ebebeb",
    borderRadius: "10px",
  },
  fontFamily: "'Poppins', sans-serif",
};

export const lightTheme: DefaultTheme = {
  pallette: {
    primary: {
      main: "#a55eea",
    },
    secondary: {
      main: "#fc5c65",
    },
    background: {
      default: "#ffffff",
      paper: "#ffffff",
      input: "#f4f4f4",
      divider: "rgb(0 0 0 / 15%)",
      code: "#151f28",
    },
    text: {
      default: "#232323",
      secondary: "#000000",
      input: "#797c80",
      button: "#ffffff",
      heading: "#232323",
    },
  },
  utils: {
    boxShadowLight: "0px 2px 4px 0px rgb(0 0 0 / 5%)",
    boxShadowCard: "0 8px 20px rgb(32 54 86 / 15%)",
    boxShadowPrimary: "0 8px 20px rgb(165 94 234 / 25%)",
    border: "1px solid #ebebeb",
    borderRadius: "10px",
  },
  fontFamily: "'Poppins', sans-serif",
};
