import * as React from "react";

import Logo from "@components/Logo";
import info from "@constants/branding";

import {
  Container,
  Content,
  Copyright,
  Navigation,
  NavigationLink,
  EzoicCertified,
} from "./Footer.styles";

import legal from "@constants/legal";

export interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  return (
    <Container>
      <Content>
        <Logo size="small" />
        <Navigation>
          {legal.map((item) => (
            <li key={item.path}>
              <NavigationLink to={item.path}>{item.label}</NavigationLink>
            </li>
          ))}
        </Navigation>
        <Copyright>{`© ${new Date().getFullYear()} ${info.title}`}</Copyright>
      </Content>
      <EzoicCertified id="ezoic-accreditation-container" />
    </Container>
  );
};

export default Footer;
