import styled from "styled-components";

import { shadeColor } from "@styles";

export const Container = styled.input`
  font-family: ${(props) => props.theme.fontFamily};
  padding: 8px 24px;
  font-size: 14px;
  line-height: 1.6;
  background-color: ${(props) => props.theme.pallette.background.input};
  color: ${(props) => props.theme.pallette.text.input};
  border-radius: 10px;
  border: 1px solid transparent;
  display: block;
  width: calc(100% - 50px);
  transition: border 300ms;

  &:focus,
  &:active {
    outline: none;
    border: 1px solid ${(props) => props.theme.pallette.primary.main};
  }

  &::placeholder {
    color: ${(props) => shadeColor(props.theme.pallette.text.input, 50)};
  }
`;
